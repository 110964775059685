import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { Observable } from 'rxjs';
import type { Intervention } from '../../interventions/models/Intervention';
import type { InterventionCreateRequest } from '../../interventions/models/InterventionCreateRequest';
import type { PendingInterventionCreateRequest } from '../../interventions/models/PendingInterventionCreateRequest';
import { InterventionService } from '../../interventions/services/intervention.service';
import { InterventionActions } from '../../interventions/store/actions/interventions';
import { loadMessages } from '../../messages/store/actions/messages';
import { MessagesApi } from '../../messages/store/api/messages';
import { DeviceMS, FailureType } from '../../site-detail/models/Device';
import {
    DeviceStateUpdateExecutionInfo,
    Execution,
    ExecutionInfo,
    ExecutionStatus,
} from '../../site-detail/models/Execution';
import { Site, SiteId } from '../../site-detail/models/Site';
import { UiProfile } from '../../site-detail/models/UiProfile';
import { DeviceService } from '../../site-detail/services/device.service';
import { DeviceActions } from '../../site-detail/store/actions/device-detail';
import { SiteDevicesActions } from '../../site-detail/store/actions/devices';
import { accessGrantedServego, loadSiteInfo } from '../../site-detail/store/actions/site';
import {
    removePendingInterventionAction,
    updateDeviceDefinitionUI,
    updateDeviceStateToSyncExecutionStatusById,
    updateExecutionStatus,
    updateManualExecution,
} from '../../site-detail/store/actions/ui';
import { getSelectedSiteDevices } from '../../site-detail/store/selectors/devices';
import { getSelectedSite } from '../../site-detail/store/selectors/site';
import {
    getUiDeviceDefinitions,
    getUIDevicesSectionsSync,
    getUIDevicesStatesSync,
    getUIManualExecutions,
    getUIPendingInterventionActions,
} from '../../site-detail/store/selectors/ui';
import { getUserSiteName } from '../../site-detail/utils/owner.util';
import { getSites } from '../../site-list/store/selectors/site-list';
import { now } from '../../utils/date';
import { VIDEO_CALL_STATUS } from '../../video-conference/models/video-call';
import { VideoCallsActions } from '../../video-conference/store/actions/videoCall';
import { WEBSOCKET } from '../injectionTokens';
import {
    ToasterAccessGrantedMessage,
    ToasterDeviceStateRefreshFailedMessage,
    ToasterDeviceUpdateMessage,
    ToasterJoinVideoCallMessage,
    ToasterMessageType,
    ToasterNewMailReceivedMessage,
    ToasterServegoChangedMessage,
} from '../models/Toaster';
import {
    Actions,
    Event,
    SetExecutionsMessage,
    SetUserIdMessage,
    UISocketCreatedMeetingVideoCallEvent,
    UISocketCreatedMeetingVideoCallEventMessage,
    UISocketDeviceCreated,
    UISocketDeviceLabelUpdated,
    UISocketDeviceRemoved,
    UISocketDeviceStateUpdateMessage,
    UISocketDeviceStateUpdateState,
    UISocketExecutionUpdateMessage,
    UISocketInterventionAccessGrantedMessage,
    UISocketJoinedParticipantVideoCallEvent,
    UISocketJoinedParticipantVideoCallEventMessage,
    UISocketLeaveVideoCallEvent,
    UISocketLeaveVideoCallEventMessage,
    UISocketLeftParticipantVideoCallEvent,
    UISocketLeftParticipantVideoCallEventMessage,
    UISocketMessage,
    UISocketNewInstallerMessageMessage,
    UISocketServegoAccessGrantedMessage,
} from '../models/UISocket';
import { User } from '../models/User';
import { addToasterMessage } from '../store/actions/addToasterMessage';
import { AppState } from '../store/app-state';
import { getUserLoginState } from '../store/selectors/login';
import { ErrorService } from './error.service';

@Injectable()
export class UISocketService {
    readonly userLogin$: Observable<User>;
    userLogin: User = null;

    readonly sites$: Observable<Record<SiteId, Site>>;
    sites: Record<SiteId, Site>;

    readonly selectedSite$: Observable<Site>;
    selectedSite: Site;

    readonly devices$: Observable<DeviceMS[]>;
    devices: DeviceMS[] = [];

    readonly devicesStatesSync$: Observable<Array<DeviceStateUpdateExecutionInfo>>;
    devicesStatesSync: Array<DeviceStateUpdateExecutionInfo> = null;

    readonly devicesSectionsSync$: Observable<Array<ExecutionInfo>>;
    devicesSectionsSync: Array<ExecutionInfo> = null;

    readonly uiDeviceDefinitions$: Observable<Record<string, any>>;
    uiDeviceDefinitions: Record<string, any> = {};

    readonly manualExecutions$: Observable<Array<Execution>>;
    manualExecutions: Array<Execution> = null;

    readonly pendingInterventionActions$: Observable<Array<PendingInterventionCreateRequest>>;
    pendingInterventionActions: Array<PendingInterventionCreateRequest> = null;

    public constructor(
        private deviceService: DeviceService,
        private messageAPI: MessagesApi,
        private errorService: ErrorService,
        private interventionService: InterventionService,
        @Inject(WEBSOCKET) private socket,
        private store: Store<AppState>,
    ) {
        this.sites$ = this.store.select(getSites);
        this.selectedSite$ = this.store.select(getSelectedSite);
        this.devices$ = this.store.select(getSelectedSiteDevices);
        this.userLogin$ = this.store.select(getUserLoginState);
        this.devicesStatesSync$ = this.store.select(getUIDevicesStatesSync);
        this.devicesSectionsSync$ = this.store.select(getUIDevicesSectionsSync);
        this.uiDeviceDefinitions$ = this.store.select(getUiDeviceDefinitions);
        this.manualExecutions$ = this.store.select(getUIManualExecutions);
        this.pendingInterventionActions$ = this.store.select(getUIPendingInterventionActions);
    }

    public initSocket(): void {
        this.onEvent(Event.CONNECT).subscribe(() => {
            // eslint-disable-next-line no-console
            console.info('Connection established with server');

            if (this.userLogin?.id?.length) {
                this.setSessionUserId(this.userLogin.id);
            }

            if (this.devicesStatesSync?.length) {
                this.setExecutions(this.devicesStatesSync.map((el) => el.job_id));
            }

            if (this.devicesSectionsSync?.length) {
                this.setExecutions(this.devicesSectionsSync.map((el) => el.job_id));
            }
        });

        this.onEvent(Event.DISCONNECT).subscribe(() => {
            // eslint-disable-next-line no-console
            console.info('Disconnected from server');
        });

        // Message Handlers
        this.onMessage().subscribe((message: UISocketMessage) => {
            // eslint-disable-next-line no-console
            console.info('Message received from server', message);
        });

        this.onDeviceStateUpdateMessage().subscribe((message: UISocketDeviceStateUpdateMessage) => {
            message.states.forEach((state) =>
                this.store.dispatch(
                    DeviceActions.deviceUpdateCompleted({
                        deviceId: message.deviceId,
                        value: state.value,
                        stateId: state.name,
                    }),
                ),
            );
            this.updateSiteDevice(message.siteId, message.deviceId);
            // Format message for toaster
            const site: Site = this.sites[message.siteId];
            if (site) {
                const device: DeviceMS = this.devices.find((d) => d.id === message.deviceId);
                if (device) {
                    if (device.uiType in this.uiDeviceDefinitions) {
                        this.sendToasterDeviceUpdateStateMessage(
                            site,
                            device,
                            this.uiDeviceDefinitions[device.uiType],
                            message,
                        );
                    } else {
                        this.deviceService.getDeviceUI(device.uiType).subscribe((definition) => {
                            this.sendToasterDeviceUpdateStateMessage(site, device, definition, message);
                            this.store.dispatch(
                                updateDeviceDefinitionUI({
                                    deviceType: device.uiType,
                                    definitionUI: definition,
                                }),
                            );
                        });
                    }
                }
            }
        });

        this.onInterventionGrantedMessage().subscribe((message: UISocketInterventionAccessGrantedMessage) => {
            return this.interventionService.getInterventions(message.siteId).subscribe((interventions) => {
                this.store.dispatch(InterventionActions.loadSiteInterventions({ interventions }));
                // Format message for toaster
                const site: Site = this.sites[message.siteId];
                const intervention: Intervention = interventions.find(
                    (i) => i.sessionId === message.interventionSessionId,
                );

                if (site && intervention) {
                    const accessGrantedInterventionMessage: ToasterAccessGrantedMessage = {
                        id: crypto.randomUUID(),
                        content: {
                            accessGrantedTime: now(),
                            siteId: site.id,
                            userSiteName: getUserSiteName(site.owner),
                            intervention: intervention,
                        },
                        type: ToasterMessageType.ACCESS_GRANTED,
                    };

                    this.store.dispatch(
                        addToasterMessage({
                            message: accessGrantedInterventionMessage,
                        }),
                    );
                }
            });
        });

        this.onServegoGrantedMessage().subscribe((message: UISocketServegoAccessGrantedMessage) => {
            return this.handleServegoAccessMessages(message);
        });

        this.onExecutionUpdateMessage().subscribe((message: UISocketExecutionUpdateMessage) => {
            // Look for execution in list of device section sync
            const executionsInfoForSectionRefresh = this.devicesSectionsSync.filter(
                (exec) => exec.job_id === message.job_id,
            );

            // Look for execution in list of device state sync
            const deviceStateUpdateExecution = this.devicesStatesSync.find((exec) => exec.job_id === message.job_id);

            const manualExecutionExec = this.manualExecutions.find((exec) => exec.job_id === message.job_id);

            if (executionsInfoForSectionRefresh && executionsInfoForSectionRefresh.length > 0) {
                this.store.dispatch(
                    updateExecutionStatus({
                        execution: {
                            status: message.status,
                            job_id: message.job_id,
                        },
                    }),
                );

                if (message.status === ExecutionStatus.QUEUED_GATEWAY_SIDE) {
                    // put a 2 min timer
                    setTimeout(() => {
                        const executionsStandByInfoForSectionRefresh = this.devicesSectionsSync.filter(
                            (exec) => exec.job_id === message.job_id,
                        );
                        if (
                            executionsStandByInfoForSectionRefresh &&
                            executionsStandByInfoForSectionRefresh.length > 0
                        ) {
                            this.store.dispatch(
                                updateExecutionStatus({
                                    execution: {
                                        status: ExecutionStatus.FAILED,
                                        job_id: message.job_id,
                                    },
                                }),
                            );
                            executionsStandByInfoForSectionRefresh.map(
                                (executionStandByInfoForSectionRefresh: ExecutionInfo) => {
                                    this.verifyDefinitionAndSendStateStateTaosterMessage(
                                        executionStandByInfoForSectionRefresh,
                                        true,
                                    );
                                },
                            );
                        }
                    }, 120 * 1000);
                } else if (
                    message.status === ExecutionStatus.FAILED &&
                    message.failureType !== FailureType.CMDDEPRECATED
                ) {
                    // Format message for toaster
                    executionsInfoForSectionRefresh.map((executionInfoForSectionRefresh: ExecutionInfo) => {
                        this.verifyDefinitionAndSendStateStateTaosterMessage(executionInfoForSectionRefresh);
                    });
                }
            } else if (deviceStateUpdateExecution) {
                const site: Site = this.sites[deviceStateUpdateExecution.siteId];
                if (!site) {
                    return;
                }
                const device: DeviceMS = this.devices.find((d) => d.id === deviceStateUpdateExecution.deviceId);
                if (!device) {
                    return;
                }
                if (message.status === ExecutionStatus.QUEUED_GATEWAY_SIDE) {
                    // put a 2 min timer
                    setTimeout(() => {
                        const deviceStandByExecution = this.devicesStatesSync.find(
                            (exec) => exec.job_id === message.job_id,
                        );
                        if (deviceStandByExecution) {
                            this.store.dispatch(
                                updateDeviceStateToSyncExecutionStatusById({
                                    execution: {
                                        status: ExecutionStatus.FAILED,
                                        job_id: message.job_id,
                                    },
                                }),
                            );
                            this.saveCompletedDeviceAction(message, true);
                            this.verifyDefinitionAndSendStateUpdateTaosterMessage(deviceStateUpdateExecution, true);
                        }
                    }, 120 * 1000);
                } else if (
                    message.status === ExecutionStatus.FAILED &&
                    message.failureType !== FailureType.CMDDEPRECATED
                ) {
                    this.verifyDefinitionAndSendStateUpdateTaosterMessage(deviceStateUpdateExecution);
                } else if (message.status === ExecutionStatus.COMPLETED) {
                    this.saveCompletedDeviceAction(message);
                }
                this.store.dispatch(
                    updateDeviceStateToSyncExecutionStatusById({
                        execution: {
                            status: message.status,
                            job_id: message.job_id,
                        },
                    }),
                );
            } else if (manualExecutionExec) {
                const executionUpdated: Execution = {
                    job_id: manualExecutionExec.job_id,
                    deviceId: manualExecutionExec.deviceId,
                    state: manualExecutionExec.state,
                    // we update status with the new one
                    status: message.status,
                    failureType: message.failureType !== FailureType.NO_FAILURE ? message.failureType : undefined,
                };
                this.store.dispatch(updateManualExecution({ exec: executionUpdated }));
            }
        });

        // #SEG-1047
        this.onNewInstallerMessage().subscribe((message: UISocketNewInstallerMessageMessage) =>
            this.handleNewInstallerMessageMessage(message),
        );
        this.onDeviceCreatedMessage().subscribe((message: UISocketDeviceCreated) =>
            this.handleNewDeviceCreatedMessage(message),
        );
        this.onDeviceLabelUpdateMessage().subscribe((message: UISocketDeviceLabelUpdated) =>
            this.handleDeviceLabelUpdatedMessage(message),
        );
        this.onDeviceRemovedeMessage().subscribe((message: UISocketDeviceRemoved) =>
            this.handleDeviceRemovedMessage(message),
        );

        this.onCreatedVideoCallMessage().subscribe(({ videoCallEvent }: UISocketCreatedMeetingVideoCallEventMessage) =>
            this.handleCreatedVideoCallMessage(videoCallEvent),
        );

        this.onParticipantJoinedVideoCallMessage().subscribe(
            ({ videoCallEvent }: UISocketJoinedParticipantVideoCallEventMessage) =>
                this.handleParticipantJoinedVideoCallMessage(videoCallEvent),
        );

        this.onParticipantLeftVideoCallMessage().subscribe(
            ({ videoCallEvent }: UISocketLeftParticipantVideoCallEventMessage) =>
                this.handleParticipantLeftVideoCallMessage(videoCallEvent),
        );

        this.onLeaveVideoCallMessage().subscribe(({ videoCallEvent }: UISocketLeaveVideoCallEventMessage) =>
            this.handleLeaveVideoCallMessage(videoCallEvent),
        );

        this.userLogin$.subscribe((userLogin) => {
            if (userLogin?.id?.length) {
                this.userLogin = userLogin;
                this.setSessionUserId(this.userLogin.id);
            }
        });

        this.sites$.subscribe((sites) => {
            this.sites = sites;
        });

        this.selectedSite$.subscribe((selectedSite) => {
            this.selectedSite = selectedSite;
        });

        this.devices$.subscribe((devices) => {
            this.devices = devices;
        });

        this.devicesStatesSync$.subscribe((devicesStatesSync) => {
            this.devicesStatesSync = devicesStatesSync;
        });

        this.devicesSectionsSync$.subscribe((devicesSectionsSync) => {
            this.devicesSectionsSync = devicesSectionsSync;
        });

        this.uiDeviceDefinitions$.subscribe((uiDeviceDefinitions) => {
            this.uiDeviceDefinitions = uiDeviceDefinitions;
        });
        this.manualExecutions$.subscribe((currentManualExecutions) => {
            this.manualExecutions = currentManualExecutions;
        });
        this.pendingInterventionActions$.subscribe((currentPendingInterventionActions) => {
            this.pendingInterventionActions = currentPendingInterventionActions;
        });
    }

    private handleNewInstallerMessageMessage(message: UISocketNewInstallerMessageMessage): void {
        // todo display a toaster about new messages
        this.messageAPI.getMessageForInstaller().subscribe((messages) => {
            this.store.dispatch(loadMessages({ messages }));
            const successMessage: ToasterNewMailReceivedMessage = {
                id: crypto.randomUUID(),
                content: {
                    message: message.title,
                    title: 'INSTALLER_NEW_MESSAGE_RECEIVED_TITLE',
                },
                type: ToasterMessageType.INSTALLER_RECEIVE_NEW_MESSAGE,
            };
            this.store.dispatch(addToasterMessage({ message: successMessage, duration: 1000 }));
        });
        // todo then reload messages
    }

    private handleServegoAccessMessages(message: UISocketServegoAccessGrantedMessage) {
        this.store.dispatch(
            accessGrantedServego({
                siteId: message.siteId,
                isServego: message.isServego,
            }),
        );

        let site: Site = this.sites[message.siteId];
        if (site) {
            site = Object.assign({}, site, { isServego: message.isServego });
            this.displayToasterForServegoAccess(site);
            this.store.dispatch(loadSiteInfo({ site }));
        }
    }

    private handleNewDeviceCreatedMessage(message: UISocketDeviceCreated) {
        if (this.selectedSite.id === message.siteId) {
            this.store.dispatch(
                SiteDevicesActions.deviceCreated({
                    siteId: message.siteId,
                    device: message.device,
                }),
            );
        }
    }

    private handleDeviceRemovedMessage(message: UISocketDeviceRemoved) {
        if (this.selectedSite.id === message.siteId) {
            this.store.dispatch(
                SiteDevicesActions.deviceRemoved({
                    siteId: message.siteId,
                    deviceId: message.deviceId,
                }),
            );
        }
    }

    private handleDeviceLabelUpdatedMessage(message: UISocketDeviceLabelUpdated) {
        if (this.selectedSite.id === message.siteId) {
            this.store.dispatch(
                SiteDevicesActions.deviceLabelUpdated({
                    siteId: message.siteId,
                    deviceId: message.deviceId,
                    deviceLabel: message.deviceLabel,
                }),
            );
        }
    }

    private displayToasterForServegoAccess(site: Site) {
        const accessGrantedServegoMessage: ToasterServegoChangedMessage = {
            id: crypto.randomUUID(),
            content: {
                siteId: site.id,
                hasAccess: site.isServego,
                userSiteName: getUserSiteName(site.owner),
            },
            type: ToasterMessageType.SERVEGO_ACCESS_CHANGED,
        };
        this.store.dispatch(addToasterMessage({ message: accessGrantedServegoMessage }));
    }

    // Messages Emitter
    public setSessionUserId(userId: string): void {
        const sessionIdMessage: SetUserIdMessage = {
            userId: userId,
        };
        this.socket.emit(Actions.SET_USER_ID, sessionIdMessage);
    }

    public setExecutions(executions: string[]): void {
        const sessionSitesMessage: SetExecutionsMessage = {
            executions,
        };
        this.socket.emit(Actions.SET_EXECUTIONS, sessionSitesMessage);
    }

    // Messages Handlers
    public onEvent(event: Event): Observable<any> {
        return new Observable<Event>((observer) => {
            this.socket.on(event, () => observer.next());
        });
    }

    public onMessage(): Observable<UISocketMessage> {
        return new Observable<UISocketMessage>((observer) => {
            this.socket.on(Actions.MESSAGE, (data: UISocketMessage) => observer.next(data));
        });
    }

    public onDeviceStateUpdateMessage(): Observable<UISocketDeviceStateUpdateMessage> {
        return new Observable<UISocketDeviceStateUpdateMessage>((observer) => {
            this.socket.on(Actions.DEVICE_STATE_UPDATE, (data: UISocketDeviceStateUpdateMessage) =>
                observer.next(data),
            );
        });
    }

    public onInterventionGrantedMessage(): Observable<UISocketInterventionAccessGrantedMessage> {
        return new Observable<UISocketInterventionAccessGrantedMessage>((observer) => {
            this.socket.on(Actions.INTERVENTION_ACCESS_GRANTED, (data: UISocketInterventionAccessGrantedMessage) =>
                observer.next(data),
            );
        });
    }

    public onServegoGrantedMessage(): Observable<UISocketServegoAccessGrantedMessage> {
        return new Observable<UISocketServegoAccessGrantedMessage>((observer) => {
            this.socket.on(Actions.SERVEGO_ACCESS_GRANTED, (data: UISocketServegoAccessGrantedMessage) =>
                observer.next(data),
            );
        });
    }

    public onExecutionUpdateMessage(): Observable<UISocketExecutionUpdateMessage> {
        return new Observable<UISocketExecutionUpdateMessage>((observer) => {
            this.socket.on(Actions.EXECUTION_STATUS_UPDATE, (data: UISocketExecutionUpdateMessage) => {
                observer.next(data);
            });
        });
    }

    public onNewInstallerMessage(): Observable<UISocketNewInstallerMessageMessage> {
        return new Observable<UISocketNewInstallerMessageMessage>((observer) => {
            this.socket.on(Actions.NEW_INSTALLER_MESSAGE, (data: UISocketNewInstallerMessageMessage) => {
                observer.next(data);
            });
        });
    }

    public onDeviceLabelUpdateMessage(): Observable<UISocketDeviceLabelUpdated> {
        return new Observable<UISocketDeviceLabelUpdated>((observer) => {
            this.socket.on(Actions.DEVICE_LABEL_UPDATE, (data: UISocketDeviceLabelUpdated) => observer.next(data));
        });
    }

    public onDeviceRemovedeMessage(): Observable<UISocketDeviceRemoved> {
        return new Observable<UISocketDeviceRemoved>((observer) => {
            this.socket.on(Actions.DEVICE_REMOVED, (data: UISocketDeviceRemoved) => observer.next(data));
        });
    }

    public onDeviceCreatedMessage(): Observable<UISocketDeviceCreated> {
        return new Observable<UISocketDeviceCreated>((observer) => {
            this.socket.on(Actions.DEVICE_CREATED, (data: UISocketDeviceCreated) => observer.next(data));
        });
    }

    public onCreatedVideoCallMessage(): Observable<UISocketCreatedMeetingVideoCallEventMessage> {
        return new Observable<UISocketCreatedMeetingVideoCallEventMessage>((observer) => {
            this.socket.on(Actions.VIDEO_CALL_CREATED, (data: UISocketCreatedMeetingVideoCallEventMessage) =>
                observer.next(data),
            );
        });
    }

    public onParticipantJoinedVideoCallMessage(): Observable<UISocketJoinedParticipantVideoCallEventMessage> {
        return new Observable<UISocketJoinedParticipantVideoCallEventMessage>((observer) => {
            this.socket.on(Actions.JOINED_PARTICIPANT, (data: UISocketJoinedParticipantVideoCallEventMessage) => {
                return observer.next(data);
            });
        });
    }

    public onParticipantLeftVideoCallMessage(): Observable<UISocketLeftParticipantVideoCallEventMessage> {
        return new Observable<UISocketLeftParticipantVideoCallEventMessage>((observer) => {
            this.socket.on(Actions.LEFT_PARTICIPANT, (data: UISocketLeftParticipantVideoCallEventMessage) =>
                observer.next(data),
            );
        });
    }

    public onLeaveVideoCallMessage(): Observable<UISocketLeaveVideoCallEventMessage> {
        return new Observable<UISocketLeaveVideoCallEventMessage>((observer) => {
            this.socket.on(Actions.LEAVE_CALL, (data: UISocketLeaveVideoCallEventMessage) => observer.next(data));
        });
    }

    private sendToasterDeviceUpdateStateMessage(
        site: Site,
        device: DeviceMS,
        deviceDefinitionUI: UiProfile,
        message: UISocketDeviceStateUpdateMessage,
    ) {
        message.states
            .filter((state) => this.devicesStatesSync.some((job) => job.state === state.name))
            .forEach((state: UISocketDeviceStateUpdateState) => {
                const deviceUpdateMessage: ToasterDeviceUpdateMessage = {
                    type: ToasterMessageType.DEVICE_UPDATE_STATE,
                    id: crypto.randomUUID(),
                    content: {
                        siteId: site.id,
                        deviceId: message.deviceId,
                        deviceName: device['name'],
                        isUpdateValid: true,
                        stateLabel: 'unknown',
                        userSiteName: getUserSiteName(site.owner),
                        newValue: state.value,
                    },
                };

                // Get stateLabel from ui definition
                const uiProperty = deviceDefinitionUI.properties[state.name];
                if (uiProperty) {
                    deviceUpdateMessage.content.stateLabel = uiProperty.label;
                    this.store.dispatch(addToasterMessage({ message: deviceUpdateMessage }));
                }
            });
    }

    private sendDeviceStateRefreshFailedOrQueuedMessage(
        site: Site,
        device: DeviceMS,
        deviceDefinitionUI: UiProfile,
        executionInfo: ExecutionInfo,
        queued = false,
    ) {
        const content = {
            siteId: site.id,
            deviceId: device.id,
            deviceName: device['name'],
            stateLabel: 'unknown',
            isQueued: queued,
            userSiteName: getUserSiteName(site.owner),
        };
        const deviceStateRefreshFailedMessage: ToasterDeviceStateRefreshFailedMessage = {
            type: ToasterMessageType.DEVICE_REFRESH_STATE_FAILED,
            id: crypto.randomUUID(),
            content,
        };

        // Get stateLabel from ui definition

        // Get stateLabel from ui definition
        const uiProperty = deviceDefinitionUI.properties[executionInfo.state];
        if (uiProperty) {
            deviceStateRefreshFailedMessage.content.stateLabel = uiProperty.label;
        }

        this.errorService.showToasterError(deviceStateRefreshFailedMessage, {
            duration: 15 * 1000, // how long do we want to display the error toaster
        });
        Sentry.captureMessage('device_refresh_state_failed', {
            extra: content,
        });
    }

    private saveCompletedDeviceAction(message: UISocketExecutionUpdateMessage, queued = false) {
        const pendingAction = this.pendingInterventionActions.find((a) => a.job_id === message.job_id);
        const newIntervention: InterventionCreateRequest = pendingAction;
        if (queued) {
            newIntervention.data.queued = true;
        }
        this.interventionService.createInterventionEvent(newIntervention).subscribe((updatedIntervention) => {
            this.store.dispatch(
                removePendingInterventionAction({
                    interventionAction: pendingAction,
                }),
            );
            if (!queued) {
                this.updateSiteDevice(pendingAction.id_site, pendingAction.id_device);
            }
            this.store.dispatch(InterventionActions.updateSiteIntervention({ intervention: updatedIntervention }));
        });
    }

    private updateSiteDevice(id_site: string, id_device: string) {
        // TODO needs refacto; reload full device to pass through value conversion/transform/... defined in
        //  DeviceDetailComponent:updateDeviceDefinition(), so value is correctly store in store;
        //  refacto must go further by rethink and normalize store AND move all business conversion in
        //  MS Device ; transforms for humans reading purpose are only behaviors that must be kept here
        this.deviceService.getDeviceById(id_device).subscribe((newDevice) => {
            this.store.dispatch(SiteDevicesActions.deviceUpdated({ siteId: id_site, device: newDevice }));
        });
    }

    private verifyDefinitionAndSendStateStateTaosterMessage(executionInfo: ExecutionInfo, isQueued = false) {
        const site: Site = this.sites[executionInfo.siteId];
        if (!site) {
            return;
        }
        const device: DeviceMS = this.devices.find((d) => d.id === executionInfo.deviceId);
        if (!device) {
            return;
        }
        if (device.type in this.uiDeviceDefinitions) {
            this.sendDeviceStateRefreshFailedOrQueuedMessage(
                site,
                device,
                this.uiDeviceDefinitions[device.type],
                executionInfo,
                isQueued,
            );
        } else {
            // Get device definition from rest api
            this.deviceService.getDeviceUI(device.uiType).subscribe((definition) => {
                this.sendDeviceStateRefreshFailedOrQueuedMessage(site, device, definition, executionInfo, isQueued);
            });
        }
    }

    private verifyDefinitionAndSendStateUpdateTaosterMessage(
        executionInfo: DeviceStateUpdateExecutionInfo,
        isQueued = false,
    ) {
        const site: Site = this.sites[executionInfo.siteId];
        if (!site) {
            return;
        }
        const device: DeviceMS = this.devices.find((d) => d.id === executionInfo.deviceId);
        if (!device) {
            return;
        }
        if (device.type in this.uiDeviceDefinitions) {
            this.sendDeviceStateUpdateFailedOrQueuedMessage(
                site,
                device,
                this.uiDeviceDefinitions[device.type],
                executionInfo,
                isQueued,
            );
        } else {
            // Get device definition from rest api
            this.deviceService.getDeviceUI(device.uiType).subscribe((definition) => {
                this.sendDeviceStateUpdateFailedOrQueuedMessage(site, device, definition, executionInfo, isQueued);
            });
        }
    }

    private sendDeviceStateUpdateFailedOrQueuedMessage(
        site: Site,
        device: DeviceMS,
        deviceDefinitionUI: UiProfile,
        executionInfo: DeviceStateUpdateExecutionInfo,
        queued = false,
    ) {
        const deviceUpdateMessage: ToasterDeviceUpdateMessage = {
            type: ToasterMessageType.DEVICE_UPDATE_STATE,
            id: crypto.randomUUID(),
            content: {
                siteId: site.id,
                deviceId: device.id,
                deviceName: device['name'],
                isUpdateValid: queued,
                isQueued: queued,
                stateLabel: 'unknown',
                userSiteName: getUserSiteName(site.owner),
            },
        };

        // Get stateLabel from ui definition
        const uiProperty = deviceDefinitionUI.properties[executionInfo.state];
        if (uiProperty) {
            deviceUpdateMessage.content.stateLabel = uiProperty.label;
        }

        this.errorService.showToasterError(deviceUpdateMessage, {
            duration: 45 * 1000, // how long do we want to display the error toaster
        });
    }

    private handleCreatedVideoCallMessage(event: UISocketCreatedMeetingVideoCallEvent) {
        this.store.dispatch(
            VideoCallsActions.videoCallStarted({
                siteId: event.siteId,
                videoCall: {
                    id: event.id,
                    companyId: event.companyId,
                    siteId: event.siteId,
                    installerId: event.installerId,
                    createdAt: event.createdAt,
                    url: event.url,
                    ownerToken: event.ownerToken,
                    status: VIDEO_CALL_STATUS.INVITATION_SENT,
                },
            }),
        );
    }

    private handleParticipantJoinedVideoCallMessage(event: UISocketJoinedParticipantVideoCallEvent) {
        this.store.dispatch(
            VideoCallsActions.videoCallStatusUpdated({
                siteId: event.siteId,
                status: event.isOwner ? VIDEO_CALL_STATUS.IN_PROGRESS : VIDEO_CALL_STATUS.AVAILABLE,
            }),
        );

        if (!event.isOwner) {
            const joinVideoCallMessage: ToasterJoinVideoCallMessage = {
                id: crypto.randomUUID(),
                content: {
                    siteId: event.siteId,
                    url: event.url,
                    ownerToken: event.ownerToken,
                    userName: event.userName,
                },
                type: ToasterMessageType.GUEST_JOINED_VIDEO_CALL,
            };

            this.store.dispatch(
                addToasterMessage({
                    message: joinVideoCallMessage,
                }),
            );
        }
    }

    private handleParticipantLeftVideoCallMessage(event: UISocketLeftParticipantVideoCallEvent) {
        this.store.dispatch(
            VideoCallsActions.videoCallStatusUpdated({
                siteId: event.siteId,
                status: event.isOwner ? VIDEO_CALL_STATUS.AVAILABLE : VIDEO_CALL_STATUS.IN_PROGRESS,
            }),
        );
    }

    private handleLeaveVideoCallMessage(event: UISocketLeaveVideoCallEvent) {
        this.store.dispatch(VideoCallsActions.videoCallStopped({ siteId: event.siteId }));
    }
}
